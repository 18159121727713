@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Light.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Sora';
    src: url('./fonts/Sora-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Sora';
    src: url('./fonts/Sora-Light.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'Sora';
    src: url('./fonts/Sora-Regular.ttf') format('truetype');
    font-weight: 400;
}
